import 'flexslider';

class Story {
	private readonly host = document.getElementById('story-people')!;
	private readonly sliderContainer = this.host.querySelector('.slider-container')!;

	constructor() {
		$(this.sliderContainer).flexslider({
			animation: 'fade',
			controlNav: true,
			directionNav: false,
			slideshowSpeed: 5000,
			animationSpeed: 1000,
			animationLoop: true,
			smoothHeight: true,
			useCSS: true,
		});
	}
}

$(() => new Story());
